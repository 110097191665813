// 上传图片等文件管理
<template>
    <div class="doc-box" v-loading="loading">
        <div class="staff-box">
        <!-- 顶部栏 -->
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>文件管理</el-breadcrumb-item>
            <el-breadcrumb-item>上传文件</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item>
                    <el-autocomplete
                    class="inline-input"
                    v-model="staff"
                    :fetch-suggestions="getStaff"
                    placeholder="查询上传人"
                    @select="handleSelect"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>

        </div>
        <!-- content -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    label="文件">
                    <template slot-scope="scope">
                        <div v-if="getFileType(scope.row.url)=='img'">
                             <el-image
                                style="width: 100px; height: 100px"
                                :src="scope.row.url"
                                :preview-src-list="imgs"
                                fit="contain"></el-image>
                        </div>
                        <div class="link" v-else>
                            <i class="el-icon-document" />
                            <a :href="scope.row.url" target="_blank" >{{scope.row.url}}</a>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="username"
                    label="上传人">
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="上传时间">
                </el-table-column>
                <el-table-column
                    prop="bind_type"
                    label="类型">
                    <template slot-scope="scope">
                        <div class="class">{{scope.row.bind_type}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                v-if="addTaskRule"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.id)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <el-empty v-if="list.length<=0" description="还没有上传文件"></el-empty>
        </div>
        <!-- page分页 -->
        <div class="pageNav" v-if="list.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import {formatTime,checkRules} from '@/utils/util.js'
export default {
    data(){
        return{
            staff:'',
            imgUrl:'',
            page:1,
            totalPage:1,
            pageArr:[5,10,15,20],
            loading:false,
            addTaskRule:checkRules(15),//文件管理权限
            form:{
                keys:'',
                start:'',
                end:'',
            },
            pageSize:10,
            list:[],
            timeArr:{},
            imgs:[]
        }
    },
    created(){
        this.imgUrl = this.API.apiUrl
        let now = formatTime(new Date) 
        ,str = now.split(" ")[0]
        let start = str.split("-"),
        start_time = `${start[0]}/${start[1]}/01 00:00:00`
        this.timeArr=[new Date(start_time),new Date(new Date().getTime()+24*60*60*1000)]
        this.getImages()
    },
    filters:{
        getUrl(url,API){
            //console.log(API+url)
            return API+url
        }
    },
    methods:{
        //删除
        handleDelete(id){
            //console.log(tid)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.loading = true
                    let params = {
                        method:'delFile',
                        id
                    }
                    this.loading=true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //刷新
                        this.loading = false
                        this.getImages()
                        this.$message({
                            message: res.msg||this.CONST.DEL_SUC,
                            type: 'success'
                        });
                    }).catch(err=>{
                        this.loading = false 
                        this.$message({
                            message: err.msg||this.CONST.DEL_FAILD,
                            type: 'warning'
                        });
                    })

            }).catch(err=>{
                console.log(err)
            })
        },
        //获取员工姓名
        async  getStaff(queryString, cb){
            let staffList=[]
            ,params = {
                    method:"getStaffList",
                    page:0,
                    keys:this.form.staff_name,
                    pageSize:10
                }
                let {data} =await this.$ajax.post(this.API.api,params)
                // console.log(data)
                // console.log(data.list)
                if(data.list){
                    data.list.forEach((item)=>{
                        staffList.push({
                            value:item.username,
                            id:item.userid
                        })
                    })
                    this.staffList = staffList
                    //搜索
                    this.querySearchStaff(queryString, cb)
                }
        },
        handleSelect(e){
            //console.log(e)
            if(e.id){
                this.form.keys = e.id
                this.getImages()
            }
            
        },
        getFileType(url){
            let arr = url.split('.'),
            type = arr[arr.length-1]
            console.log(arr)
            if(type=='jpg'||type=='jpeg'||type=='png'||type=="dmp"||type=="PNG"){
                return 'img'
            }
            return type
        },
        choisePageSize(pageSize){
            this.pageSize = pageSize 
            this.search()
        },
        currentChange(e){
            this.page = e 
            this.getImages()
            console.log(e,'---')
        },
        search(){
            this.page = 1
            this.getImages()
        },
         querySearchStaff(queryString, cb) {
            var staffList = this.staffList;
            //console.log(staffList)
            var results = queryString ? staffList.filter(this.createFilterStaff(queryString)) : staffList;
            // 调用 callback 返回建议列表的数据
            console.log(results,staffList)
            cb(results);
        },
        createFilterStaff(queryString) {
            return (staffList) => {
            return (staffList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        getImages(){
            let params = this.form 
            params.method = "getImagesfile"
            params.start=formatTime(this.timeArr[0]),
            params.end=formatTime(this.timeArr[1])
            params.page = this.page
            params.pageSize = this.pageSize
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                if(res.data.list){
                    this.list = res.data.list 
                    let that = this,imgs=[]
                    res.data.list.forEach(item => {
                        item.url = that.imgUrl+item.url
                        item.type = that.getFileType(item.url)
                        if(item.type=='img'){
                            imgs.push(item.url)
                        }
                    });
                    that.imgs=imgs
                    this.totalPage = res.data.totalPage
                }
                this.loading = false
            }).catch(err=>{console.log(err.data)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        }
    }
}
</script>